<template>
  <div>
    <join-card heading="Join to Retina" :include-name="true">
      <div class="text-red-400 w-full max-w-xs mx-auto text-center mt-4">
        {{ form_message }}
      </div>
      <form class="w-full" action="" @submit.prevent="submitForm">
        <div class="my-6 w-full">
          <input
            type="text"
            placeholder="4 Digit OTP : (One Time Password)"
            class="
              text-center
              block
              w-full
              max-w-xs
              mx-auto
              border-2
              rounded-lg
              focus:outline-none
              p-2
            "
            autofocus
            v-model="otp"
          />

          <show-error
            :errors="formErrors"
            name="code"
            class="w-full max-w-xs mx-auto"
          />
          <show-error
            :errors="formErrors"
            name="phone"
            class="w-full max-w-xs mx-auto"
          />
        </div>
        <div class="text-center">
          <button
            type="submit"
            class="px-8 py-1 text-xl bg-blue-600 text-white rounded shadow-sm"
          >
            Confirm
          </button>

          <div class="block text-center mt-5">
            <a href="" @click.prevent="resendOtp" class="text-sm text-blue-600">
              <status-message :background="false" :status-code="otpSendingStatus">{{ resent_otp_link_label }}</status-message>
            </a>
          </div>
        </div>
      </form>
    </join-card>
  </div>
</template>

<script>
import JoinCard from "../components/joincard.vue";
import ShowError from "../../../../components/show-error";

export default {
  components: { JoinCard, ShowError },
  name: "Confirm",
  data() {
    return {
      otp: "",
      otpSendingStatus: 200,
      resent_otp_link_label: "Resend OTP",
    };

  },
  methods: {
    resendOtp() {
      const phone = this.$route.query.phone;

      if (this.resent_otp_link_label != "Sending...") {


        this.resent_otp_link_label = "Sending...";
        this.otpSendingStatus = 200;

        this.$store.dispatch("student/join", {
          data: { phone },

          error: (response) => {
            if( response ){
              this.otpSendingStatus = response.statusCode;
            }else {
              this.otpSendingStatus = 404;
            }

            this.resent_otp_link_label = "Failed";
            setTimeout(() => (this.resent_otp_link_label = "Resend OTP"), 3000);
          },
          has_errors: (errors) => (this.formErrors = errors),
          success: (response) => {
            const data = response.data;
            if (data.has_user) {
              this.$router.push({
                path: "/login",
                query: { phone: data.phone },
              });
            } else {
              this.resent_otp_link_label = "Otp Sent...";
              setTimeout(
                () => (this.resent_otp_link_label = "Resend Otp"),
                3000
              );
            }
          },
        });
      }
    },
    submitForm() {
      this.formErrors = [];

      const phone = this.$route.query.phone;

      this.$store.dispatch("student/confirm", {
        data: { phone, code: this.otp },

        error: (response) => this.setFormMessage(response.data.message),

        has_errors: (errors) => this.setFormErrors(errors),

        success: (response) => {
          const data = response.data;

          if (data.phone) {
            this.$router.push({
              path: "/register",
              query: { phone: data.phone, otp: this.otp },
            });
          }
        },
      });
    },
  },
};
</script>

